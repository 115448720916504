@font-face {
    font-family: "The-Icon-of";
    src: url("fonts/The-Icon-of.eot?ww946b");
    src: url("fonts/The-Icon-of.eot?ww946b#iefix") format("embedded-opentype"),
        url("fonts/The-Icon-of.ttf?ww946b") format("truetype"), url("fonts/The-Icon-of.woff?ww946b") format("woff"),
        url("fonts/The-Icon-of.svg?ww946b#The-Icon-of") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="tio-"],
[class*=" tio-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "The-Icon-of" !important;
    font-size: 1.125em;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.tio-lg {
    font-size: 1.25rem;
}

.tio-xl {
    font-size: 1.75rem;
}

.tio-add-circle-outlined:before {
    content: "\e900";
}
.tio-add-circle:before {
    content: "\e901";
}
.tio-add-square-outlined:before {
    content: "\e902";
}
.tio-add-square:before {
    content: "\e903";
}
.tio-add:before {
    content: "\e904";
}
.tio-appointment-cancelled:before {
    content: "\e905";
}
.tio-appointment:before {
    content: "\e906";
}
.tio-archive:before {
    content: "\e907";
}
.tio-autorenew:before {
    content: "\e908";
}
.tio-block-ads:before {
    content: "\e909";
}
.tio-bookmark-outlined:before {
    content: "\e90a";
}
.tio-bookmark:before {
    content: "\e90b";
}
.tio-bookmarks-outlined:before {
    content: "\e90c";
}
.tio-bookmarks:before {
    content: "\e90d";
}
.tio-browser-window:before {
    content: "\e90e";
}
.tio-browser-windows:before {
    content: "\e90f";
}
.tio-cached:before {
    content: "\e910";
}
.tio-calendar-month:before {
    content: "\e911";
}
.tio-calendar-note:before {
    content: "\e912";
}
.tio-calendar:before {
    content: "\e913";
}
.tio-carousel-horizontal-outlined:before {
    content: "\e914";
}
.tio-carousel-horizontal:before {
    content: "\e915";
}
.tio-carousel-vertical-outlined:before {
    content: "\e916";
}
.tio-carousel-vertical:before {
    content: "\e917";
}
.tio-checkmark-circle-outlined:before {
    content: "\e918";
}
.tio-checkmark-circle:before {
    content: "\e919";
}
.tio-checkmark-square-outlined:before {
    content: "\e91a";
}
.tio-checkmark-square:before {
    content: "\e91b";
}
.tio-clear-circle-outlined:before {
    content: "\e91c";
}
.tio-clear-circle:before {
    content: "\e91d";
}
.tio-clear-square-outlined:before {
    content: "\e91e";
}
.tio-clear-square:before {
    content: "\e91f";
}
.tio-clear:before {
    content: "\e920";
}
.tio-column-view-outlined:before {
    content: "\e921";
}
.tio-column-view:before {
    content: "\e922";
}
.tio-command-outlined:before {
    content: "\e923";
}
.tio-command:before {
    content: "\e924";
}
.tio-date-range:before {
    content: "\e925";
}
.tio-done:before {
    content: "\e926";
}
.tio-drag:before {
    content: "\e927";
}
.tio-eject-circle-outlined:before {
    content: "\e928";
}
.tio-eject-circle:before {
    content: "\e929";
}
.tio-eject:before {
    content: "\e92a";
}
.tio-event:before {
    content: "\e92b";
}
.tio-exit-fullscreen-1-1:before {
    content: "\e92c";
}
.tio-exit-fullscreen-4-3:before {
    content: "\e92d";
}
.tio-exit-fullscreen-16-9:before {
    content: "\e92e";
}
.tio-find-replace:before {
    content: "\e92f";
}
.tio-first-page:before {
    content: "\e930";
}
.tio-hashtag:before {
    content: "\e931";
}
.tio-heart-outlined:before {
    content: "\e932";
}
.tio-heart:before {
    content: "\e933";
}
.tio-help-outlined:before {
    content: "\e934";
}
.tio-help:before {
    content: "\e935";
}
.tio-history:before {
    content: "\e936";
}
.tio-incognito:before {
    content: "\e937";
}
.tio-info-outined:before {
    content: "\e938";
}
.tio-info:before {
    content: "\e939";
}
.tio-key:before {
    content: "\e93a";
}
.tio-label-important:before {
    content: "\e93b";
}
.tio-label-off:before {
    content: "\e93c";
}
.tio-label-outlined:before {
    content: "\e93d";
}
.tio-label:before {
    content: "\e93e";
}
.tio-labels-outlined:before {
    content: "\e93f";
}
.tio-labels:before {
    content: "\e940";
}
.tio-last-page:before {
    content: "\e941";
}
.tio-layout:before {
    content: "\e942";
}
.tio-move-page:before {
    content: "\e943";
}
.tio-multi-direction-diagonal:before {
    content: "\e944";
}
.tio-multi-direction:before {
    content: "\e945";
}
.tio-notebook-bookmarked:before {
    content: "\e946";
}
.tio-notice-outlined:before {
    content: "\e947";
}
.tio-notice:before {
    content: "\e948";
}
.tio-open-in-new:before {
    content: "\e949";
}
.tio-pages-outlined:before {
    content: "\e94a";
}
.tio-pages:before {
    content: "\e94b";
}
.tio-publish:before {
    content: "\e94c";
}
.tio-record-screen:before {
    content: "\e94d";
}
.tio-refresh:before {
    content: "\e94e";
}
.tio-remaining-time:before {
    content: "\e94f";
}
.tio-remove-circle-outlined:before {
    content: "\e950";
}
.tio-remove-circle:before {
    content: "\e951";
}
.tio-remove-square-outlined:before {
    content: "\e952";
}
.tio-remove-square:before {
    content: "\e953";
}
.tio-remove:before {
    content: "\e954";
}
.tio-restore:before {
    content: "\e955";
}
.tio-search:before {
    content: "\e956";
}
.tio-settings-back:before {
    content: "\e957";
}
.tio-shopping-basket-add:before {
    content: "\e958";
}
.tio-shopping-basket-clear:before {
    content: "\e959";
}
.tio-shopping-basket-outlined:before {
    content: "\e95a";
}
.tio-shopping-basket-remove:before {
    content: "\e95b";
}
.tio-shopping-basket:before {
    content: "\e95c";
}
.tio-shopping-cart-add:before {
    content: "\e95d";
}
.tio-shopping-cart-off:before {
    content: "\e95e";
}
.tio-shopping-cart-outlined:before {
    content: "\e95f";
}
.tio-shopping-cart-remove:before {
    content: "\e960";
}
.tio-shopping-cart:before {
    content: "\e961";
}
.tio-snooze-notification:before {
    content: "\e962";
}
.tio-snooze:before {
    content: "\e963";
}
.tio-sort:before {
    content: "\e964";
}
.tio-star-half:before {
    content: "\e965";
}
.tio-star-outlined:before {
    content: "\e966";
}
.tio-star:before {
    content: "\e967";
}
.tio-subscribe:before {
    content: "\e968";
}
.tio-support:before {
    content: "\e969";
}
.tio-sync-off:before {
    content: "\e96a";
}
.tio-sync:before {
    content: "\e96b";
}
.tio-tab:before {
    content: "\e96c";
}
.tio-tabs:before {
    content: "\e96d";
}
.tio-time-20-s:before {
    content: "\e96e";
}
.tio-time-30-s:before {
    content: "\e96f";
}
.tio-time-40-s:before {
    content: "\e970";
}
.tio-time:before {
    content: "\e971";
}
.tio-timer-20-s:before {
    content: "\e972";
}
.tio-timer-30-s:before {
    content: "\e973";
}
.tio-timer-40-s:before {
    content: "\e974";
}
.tio-timer-off:before {
    content: "\e975";
}
.tio-timer:before {
    content: "\e976";
}
.tio-turn-off:before {
    content: "\e977";
}
.tio-unarchive:before {
    content: "\e978";
}
.tio-update:before {
    content: "\e979";
}
.tio-vibrations-off:before {
    content: "\e97a";
}
.tio-vibrations:before {
    content: "\e97b";
}
.tio-watch-later:before {
    content: "\e97c";
}
.tio-youtube-search:before {
    content: "\e97d";
}
.tio-battery-alert:before {
    content: "\e97e";
}
.tio-error-outlined:before {
    content: "\e97f";
}
.tio-error:before {
    content: "\e980";
}
.tio-message-failed-outlined:before {
    content: "\e981";
}
.tio-message-failed:before {
    content: "\e982";
}
.tio-new-release-outlined:before {
    content: "\e983";
}
.tio-new-release:before {
    content: "\e984";
}
.tio-notification-add:before {
    content: "\e985";
}
.tio-notifications-alert:before {
    content: "\e986";
}
.tio-notifications-off:before {
    content: "\e987";
}
.tio-notifications-on-outlined:before {
    content: "\e988";
}
.tio-notifications-on:before {
    content: "\e989";
}
.tio-notifications-outlined:before {
    content: "\e98a";
}
.tio-notifications:before {
    content: "\e98b";
}
.tio-report-outlined:before {
    content: "\e98c";
}
.tio-report:before {
    content: "\e98d";
}
.tio-security-warning-outlined:before {
    content: "\e98e";
}
.tio-security-warning:before {
    content: "\e98f";
}
.tio-warning-outlined:before {
    content: "\e990";
}
.tio-warning:before {
    content: "\e991";
}
.tio-arrow-backward:before {
    content: "\e992";
}
.tio-arrow-doublesided-horizontal:before {
    content: "\e993";
}
.tio-arrow-doublesided-vertical:before {
    content: "\e994";
}
.tio-arrow-downward:before {
    content: "\e995";
}
.tio-arrow-drop-down-circle-outlined:before {
    content: "\e996";
}
.tio-arrow-drop-down-circle:before {
    content: "\e997";
}
.tio-arrow-drop-up-circle-outlined:before {
    content: "\e998";
}
.tio-arrow-drop-up-circle:before {
    content: "\e999";
}
.tio-arrow-forward:before {
    content: "\e99a";
}
.tio-arrow-large-backward-outlined:before {
    content: "\e99b";
}
.tio-arrow-large-backward:before {
    content: "\e99c";
}
.tio-arrow-large-downward-outlined:before {
    content: "\e99d";
}
.tio-arrow-large-downward:before {
    content: "\e99e";
}
.tio-arrow-large-forward-outlined:before {
    content: "\e99f";
}
.tio-arrow-large-forward:before {
    content: "\e9a0";
}
.tio-arrow-large-upward-outlined:before {
    content: "\e9a1";
}
.tio-arrow-large-upward:before {
    content: "\e9a2";
}
.tio-arrow-left-circle-outlined:before {
    content: "\e9a3";
}
.tio-arrow-left-circle:before {
    content: "\e9a4";
}
.tio-arrow-long-down:before {
    content: "\e9a5";
}
.tio-arrow-long-left:before {
    content: "\e9a6";
}
.tio-arrow-long-right:before {
    content: "\e9a7";
}
.tio-arrow-long-up:before {
    content: "\e9a8";
}
.tio-arrow-right-circle-outlined:before {
    content: "\e9a9";
}
.tio-arrow-right-circle:before {
    content: "\e9aa";
}
.tio-arrow-upward:before {
    content: "\e9ab";
}
.tio-back-ui:before {
    content: "\e9ac";
}
.tio-caret-down:before {
    content: "\e9ad";
}
.tio-caret-left:before {
    content: "\e9ae";
}
.tio-caret-right:before {
    content: "\e9af";
}
.tio-caret-up:before {
    content: "\e9b0";
}
.tio-chevron-down:before {
    content: "\e9b1";
}
.tio-chevron-left:before {
    content: "\e9b2";
}
.tio-chevron-right:before {
    content: "\e9b3";
}
.tio-chevron-up:before {
    content: "\e9b4";
}
.tio-collapse-all:before {
    content: "\e9b5";
}
.tio-double-caret-horizontal:before {
    content: "\e9b6";
}
.tio-double-caret-vertical:before {
    content: "\e9b7";
}
.tio-down-ui:before {
    content: "\e9b8";
}
.tio-expand-all:before {
    content: "\e9b9";
}
.tio-next-ui:before {
    content: "\e9ba";
}
.tio-pull-right-left:before {
    content: "\e9bb";
}
.tio-subdirectory-left:before {
    content: "\e9bc";
}
.tio-subdirectory-right:before {
    content: "\e9bd";
}
.tio-up-ui:before {
    content: "\e9be";
}
.tio-fast-forward:before {
    content: "\e9bf";
}
.tio-fast-rewind:before {
    content: "\e9c0";
}
.tio-forward:before {
    content: "\e9c1";
}
.tio-mic-off-outlined:before {
    content: "\e9c2";
}
.tio-mic-off:before {
    content: "\e9c3";
}
.tio-mic-outlined:before {
    content: "\e9c4";
}
.tio-mic:before {
    content: "\e9c5";
}
.tio-music-off:before {
    content: "\e9c6";
}
.tio-music:before {
    content: "\e9c7";
}
.tio-pause-circle-outlined:before {
    content: "\e9c8";
}
.tio-pause-circle:before {
    content: "\e9c9";
}
.tio-pause:before {
    content: "\e9ca";
}
.tio-play-circle-outlined:before {
    content: "\e9cb";
}
.tio-play-circle:before {
    content: "\e9cc";
}
.tio-play-outlined:before {
    content: "\e9cd";
}
.tio-play:before {
    content: "\e9ce";
}
.tio-record-outlined:before {
    content: "\e9cf";
}
.tio-record-voice-outlined:before {
    content: "\e9d0";
}
.tio-record-voice:before {
    content: "\e9d1";
}
.tio-record:before {
    content: "\e9d2";
}
.tio-repeat:before {
    content: "\e9d3";
}
.tio-replay:before {
    content: "\e9d4";
}
.tio-shuffle:before {
    content: "\e9d5";
}
.tio-skip-back:before {
    content: "\e9d6";
}
.tio-skip-forward:before {
    content: "\e9d7";
}
.tio-skip-next:before {
    content: "\e9d8";
}
.tio-skip-previous:before {
    content: "\e9d9";
}
.tio-skip:before {
    content: "\e9da";
}
.tio-stop-circle-outlined:before {
    content: "\e9db";
}
.tio-stop-circle:before {
    content: "\e9dc";
}
.tio-stop-outlined:before {
    content: "\e9dd";
}
.tio-stop:before {
    content: "\e9de";
}
.tio-tune-horizontal:before {
    content: "\e9df";
}
.tio-tune-vertical:before {
    content: "\e9e0";
}
.tio-tune:before {
    content: "\e9e1";
}
.tio-voice-line:before {
    content: "\e9e2";
}
.tio-voice:before {
    content: "\e9e3";
}
.tio-volume-down:before {
    content: "\e9e4";
}
.tio-volume-low:before {
    content: "\e9e5";
}
.tio-volume-minus:before {
    content: "\e9e6";
}
.tio-volume-mute:before {
    content: "\e9e7";
}
.tio-volume-off:before {
    content: "\e9e8";
}
.tio-volume-plus:before {
    content: "\e9e9";
}
.tio-volume-up:before {
    content: "\e9ea";
}
.tio-adaptive-lighting:before {
    content: "\e9eb";
}
.tio-airbag-deactivated:before {
    content: "\e9ec";
}
.tio-airbag-warning:before {
    content: "\e9ed";
}
.tio-alternator-warning-outlined:before {
    content: "\e9ee";
}
.tio-alternator-warning:before {
    content: "\e9ef";
}
.tio-bonnet-open:before {
    content: "\e9f0";
}
.tio-boot-open:before {
    content: "\e9f1";
}
.tio-brake-pad-warning:before {
    content: "\e9f2";
}
.tio-cruise-control-on:before {
    content: "\e9f3";
}
.tio-dipped-beam-headlights:before {
    content: "\e9f4";
}
.tio-direction-indicators:before {
    content: "\e9f5";
}
.tio-door-open:before {
    content: "\e9f6";
}
.tio-engine-warning-outlined:before {
    content: "\e9f7";
}
.tio-engine-warning:before {
    content: "\e9f8";
}
.tio-exterior-light-fault:before {
    content: "\e9f9";
}
.tio-fog-light-front:before {
    content: "\e9fa";
}
.tio-fog-light-rear:before {
    content: "\e9fb";
}
.tio-glow-plug-warning:before {
    content: "\e9fc";
}
.tio-handbrake-warning:before {
    content: "\e9fd";
}
.tio-hazard-warning:before {
    content: "\e9fe";
}
.tio-main-beam-headlights:before {
    content: "\e9ff";
}
.tio-oil-pressure-low-outlined:before {
    content: "\ea00";
}
.tio-oil-pressure-low:before {
    content: "\ea01";
}
.tio-parking-brake-light:before {
    content: "\ea02";
}
.tio-rain-sensor:before {
    content: "\ea03";
}
.tio-rear-window-defrost:before {
    content: "\ea04";
}
.tio-recirculation:before {
    content: "\ea05";
}
.tio-seatbelt-not-on:before {
    content: "\ea06";
}
.tio-sidelight-indicator:before {
    content: "\ea07";
}
.tio-sidelight-information:before {
    content: "\ea08";
}
.tio-stability-control-off:before {
    content: "\ea09";
}
.tio-temperature-warning:before {
    content: "\ea0a";
}
.tio-tyre-pressure-low:before {
    content: "\ea0b";
}
.tio-washer-fluid-low:before {
    content: "\ea0c";
}
.tio-windscreen-defrost:before {
    content: "\ea0d";
}
.tio-call-cancelled:before {
    content: "\ea0e";
}
.tio-call-ended:before {
    content: "\ea0f";
}
.tio-call-forward:before {
    content: "\ea10";
}
.tio-call-talking-quiet:before {
    content: "\ea11";
}
.tio-call-talking:before {
    content: "\ea12";
}
.tio-call:before {
    content: "\ea13";
}
.tio-chat-outlined:before {
    content: "\ea14";
}
.tio-chat:before {
    content: "\ea15";
}
.tio-comment-image-outlined:before {
    content: "\ea16";
}
.tio-comment-image:before {
    content: "\ea17";
}
.tio-comment-outlined:before {
    content: "\ea18";
}
.tio-comment-play-outlined:before {
    content: "\ea19";
}
.tio-comment-play:before {
    content: "\ea1a";
}
.tio-comment-text-outlined:before {
    content: "\ea1b";
}
.tio-comment-text:before {
    content: "\ea1c";
}
.tio-comment-video:before {
    content: "\ea1d";
}
.tio-comment-vs-outlined:before {
    content: "\ea1e";
}
.tio-comment-vs:before {
    content: "\ea1f";
}
.tio-comment:before {
    content: "\ea20";
}
.tio-contact-notebook:before {
    content: "\ea21";
}
.tio-contacts-book:before {
    content: "\ea22";
}
.tio-contacts:before {
    content: "\ea23";
}
.tio-draft:before {
    content: "\ea24";
}
.tio-email-outlined:before {
    content: "\ea25";
}
.tio-email:before {
    content: "\ea26";
}
.tio-favorite-comment:before {
    content: "\ea27";
}
.tio-import-export:before {
    content: "\ea28";
}
.tio-inbox:before {
    content: "\ea29";
}
.tio-inboxes:before {
    content: "\ea2a";
}
.tio-made-call:before {
    content: "\ea2b";
}
.tio-message-add-outlined:before {
    content: "\ea2c";
}
.tio-message-add:before {
    content: "\ea2d";
}
.tio-message-outlined:before {
    content: "\ea2e";
}
.tio-message-play-outlined:before {
    content: "\ea2f";
}
.tio-message-play:before {
    content: "\ea30";
}
.tio-message:before {
    content: "\ea31";
}
.tio-messages-outlined:before {
    content: "\ea32";
}
.tio-messages:before {
    content: "\ea33";
}
.tio-missed-call-outgoing:before {
    content: "\ea34";
}
.tio-missed-call:before {
    content: "\ea35";
}
.tio-mms-outlined:before {
    content: "\ea36";
}
.tio-mms:before {
    content: "\ea37";
}
.tio-new-email:before {
    content: "\ea38";
}
.tio-received-call:before {
    content: "\ea39";
}
.tio-reply-all:before {
    content: "\ea3a";
}
.tio-reply:before {
    content: "\ea3b";
}
.tio-send-outlined:before {
    content: "\ea3c";
}
.tio-send:before {
    content: "\ea3d";
}
.tio-share-message:before {
    content: "\ea3e";
}
.tio-share-screen:before {
    content: "\ea3f";
}
.tio-sms-active-outlined:before {
    content: "\ea40";
}
.tio-sms-active:before {
    content: "\ea41";
}
.tio-sms-chat-outlined:before {
    content: "\ea42";
}
.tio-sms-chat:before {
    content: "\ea43";
}
.tio-sms-outlined:before {
    content: "\ea44";
}
.tio-sms:before {
    content: "\ea45";
}
.tio-swap-horizontal:before {
    content: "\ea46";
}
.tio-swap-vs:before {
    content: "\ea47";
}
.tio-swap:before {
    content: "\ea48";
}
.tio-unsubscribe:before {
    content: "\ea49";
}
.tio-voice-chat:before {
    content: "\ea4a";
}
.tio-voicemail:before {
    content: "\ea4b";
}
.tio-airpods:before {
    content: "\ea4c";
}
.tio-android-phone-vs:before {
    content: "\ea4d";
}
.tio-android-phone:before {
    content: "\ea4e";
}
.tio-android-phones:before {
    content: "\ea4f";
}
.tio-apple-watch:before {
    content: "\ea50";
}
.tio-batery-vertical-3-quarters:before {
    content: "\ea51";
}
.tio-battery-horizontal-3-quarters:before {
    content: "\ea52";
}
.tio-battery-horizontal-half:before {
    content: "\ea53";
}
.tio-battery-horizontal-outlined:before {
    content: "\ea54";
}
.tio-battery-horizontal-quarter:before {
    content: "\ea55";
}
.tio-battery-horizontal:before {
    content: "\ea56";
}
.tio-battery-vertical-half:before {
    content: "\ea57";
}
.tio-battery-vertical-outlined:before {
    content: "\ea58";
}
.tio-battery-vertical-quarter:before {
    content: "\ea59";
}
.tio-battery-vertical:before {
    content: "\ea5a";
}
.tio-calculator:before {
    content: "\ea5b";
}
.tio-charging:before {
    content: "\ea5c";
}
.tio-devices-1:before {
    content: "\ea5d";
}
.tio-devices-2:before {
    content: "\ea5e";
}
.tio-devices-apple:before {
    content: "\ea5f";
}
.tio-disc:before {
    content: "\ea60";
}
.tio-hdmi-outlined:before {
    content: "\ea61";
}
.tio-hdmi:before {
    content: "\ea62";
}
.tio-headphones:before {
    content: "\ea63";
}
.tio-imac:before {
    content: "\ea64";
}
.tio-iphone:before {
    content: "\ea65";
}
.tio-iphones:before {
    content: "\ea66";
}
.tio-joystick:before {
    content: "\ea67";
}
.tio-keyboard-hide:before {
    content: "\ea68";
}
.tio-keyboard-vs:before {
    content: "\ea69";
}
.tio-keyboard:before {
    content: "\ea6a";
}
.tio-laptop:before {
    content: "\ea6b";
}
.tio-light-battery:before {
    content: "\ea6c";
}
.tio-microphone:before {
    content: "\ea6d";
}
.tio-monitor:before {
    content: "\ea6e";
}
.tio-mouse-outlined:before {
    content: "\ea6f";
}
.tio-mouse-pad-outlined:before {
    content: "\ea70";
}
.tio-mouse-pad:before {
    content: "\ea71";
}
.tio-mouse:before {
    content: "\ea72";
}
.tio-no-sim-card:before {
    content: "\ea73";
}
.tio-photo-camera:before {
    content: "\ea74";
}
.tio-plug-outlined:before {
    content: "\ea75";
}
.tio-plug:before {
    content: "\ea76";
}
.tio-radio:before {
    content: "\ea77";
}
.tio-remote-controller:before {
    content: "\ea78";
}
.tio-router:before {
    content: "\ea79";
}
.tio-sim-card:before {
    content: "\ea7a";
}
.tio-speaker:before {
    content: "\ea7b";
}
.tio-surveillance-camera:before {
    content: "\ea7c";
}
.tio-tablet-phone:before {
    content: "\ea7d";
}
.tio-tablet:before {
    content: "\ea7e";
}
.tio-tv-new:before {
    content: "\ea7f";
}
.tio-tv-old:before {
    content: "\ea80";
}
.tio-usb-c-outlined:before {
    content: "\ea81";
}
.tio-usb-c:before {
    content: "\ea82";
}
.tio-usb-port-outlined:before {
    content: "\ea83";
}
.tio-usb-port:before {
    content: "\ea84";
}
.tio-video-camera-off:before {
    content: "\ea85";
}
.tio-video-camera-outlined:before {
    content: "\ea86";
}
.tio-video-camera:before {
    content: "\ea87";
}
.tio-vinyl:before {
    content: "\ea88";
}
.tio-watches:before {
    content: "\ea89";
}
.tio-document-outlined:before {
    content: "\ea8a";
}
.tio-document-text-outlined:before {
    content: "\ea8b";
}
.tio-document-text:before {
    content: "\ea8c";
}
.tio-document:before {
    content: "\ea8d";
}
.tio-documents-outlined:before {
    content: "\ea8e";
}
.tio-documents:before {
    content: "\ea8f";
}
.tio-file-add-outlined:before {
    content: "\ea90";
}
.tio-file-add:before {
    content: "\ea91";
}
.tio-file-outlined:before {
    content: "\ea92";
}
.tio-file-text-outlined:before {
    content: "\ea93";
}
.tio-file-text:before {
    content: "\ea94";
}
.tio-file:before {
    content: "\ea95";
}
.tio-files-labeled-outlined:before {
    content: "\ea96";
}
.tio-files-labeled:before {
    content: "\ea97";
}
.tio-files-outlined:before {
    content: "\ea98";
}
.tio-files:before {
    content: "\ea99";
}
.tio-folder-add:before {
    content: "\ea9a";
}
.tio-folder-bookmarked:before {
    content: "\ea9b";
}
.tio-folder-labeled-outlined:before {
    content: "\ea9c";
}
.tio-folder-labeled:before {
    content: "\ea9d";
}
.tio-folder-opened-labeled:before {
    content: "\ea9e";
}
.tio-folder-opened:before {
    content: "\ea9f";
}
.tio-folder-outlined:before {
    content: "\eaa0";
}
.tio-folder-photo:before {
    content: "\eaa1";
}
.tio-folder-shared:before {
    content: "\eaa2";
}
.tio-folder-special:before {
    content: "\eaa3";
}
.tio-folder:before {
    content: "\eaa4";
}
.tio-folders-outlined:before {
    content: "\eaa5";
}
.tio-folders:before {
    content: "\eaa6";
}
.tio-avocado:before {
    content: "\eaa7";
}
.tio-banana:before {
    content: "\eaa8";
}
.tio-beer-bottle-labeled:before {
    content: "\eaa9";
}
.tio-beer-bottle:before {
    content: "\eaaa";
}
.tio-chicken-outlined:before {
    content: "\eaab";
}
.tio-chicken:before {
    content: "\eaac";
}
.tio-citrus-fruits:before {
    content: "\eaad";
}
.tio-drink-outlined:before {
    content: "\eaae";
}
.tio-drink:before {
    content: "\eaaf";
}
.tio-egg:before {
    content: "\eab0";
}
.tio-fastfood:before {
    content: "\eab1";
}
.tio-fish-outlined:before {
    content: "\eab2";
}
.tio-fish:before {
    content: "\eab3";
}
.tio-fruit-apple-outlined:before {
    content: "\eab4";
}
.tio-fruit-apple:before {
    content: "\eab5";
}
.tio-ice-cream-cup:before {
    content: "\eab6";
}
.tio-ice-cream:before {
    content: "\eab7";
}
.tio-meal-outlined:before {
    content: "\eab8";
}
.tio-meal:before {
    content: "\eab9";
}
.tio-mushroom:before {
    content: "\eaba";
}
.tio-pizza-outlined:before {
    content: "\eabb";
}
.tio-pizza:before {
    content: "\eabc";
}
.tio-soda:before {
    content: "\eabd";
}
.tio-strawberry:before {
    content: "\eabe";
}
.tio-water-bottle-labeled:before {
    content: "\eabf";
}
.tio-water-bottle:before {
    content: "\eac0";
}
.tio-wine-bottle-labeled:before {
    content: "\eac1";
}
.tio-wine-bottle:before {
    content: "\eac2";
}
.tio-clap:before {
    content: "\eac3";
}
.tio-expressionless:before {
    content: "\eac4";
}
.tio-face-female:before {
    content: "\eac5";
}
.tio-face-male:before {
    content: "\eac6";
}
.tio-frowning:before {
    content: "\eac7";
}
.tio-gesture-hand-1f:before {
    content: "\eac8";
}
.tio-gesture-hand-2f:before {
    content: "\eac9";
}
.tio-gesture-hover-1f:before {
    content: "\eaca";
}
.tio-gesture-scroll-down-2f:before {
    content: "\eacb";
}
.tio-gesture-scroll-down:before {
    content: "\eacc";
}
.tio-gesture-scroll-up-2f:before {
    content: "\eacd";
}
.tio-gesture-scroll-up-down-2f:before {
    content: "\eace";
}
.tio-gesture-scroll-up-down:before {
    content: "\eacf";
}
.tio-gesture-scroll-up:before {
    content: "\ead0";
}
.tio-gesture-swipe-left-2f:before {
    content: "\ead1";
}
.tio-gesture-swipe-left-right-2f:before {
    content: "\ead2";
}
.tio-gesture-swipe-left-right:before {
    content: "\ead3";
}
.tio-gesture-swipe-left:before {
    content: "\ead4";
}
.tio-gesture-swipe-right-2f:before {
    content: "\ead5";
}
.tio-gesture-swipe-right:before {
    content: "\ead6";
}
.tio-hand-basic:before {
    content: "\ead7";
}
.tio-hand-draw:before {
    content: "\ead8";
}
.tio-hand-metal-horns:before {
    content: "\ead9";
}
.tio-hand-middle-finger:before {
    content: "\eada";
}
.tio-hand-ok:before {
    content: "\eadb";
}
.tio-hand-piece:before {
    content: "\eadc";
}
.tio-hand-wave:before {
    content: "\eadd";
}
.tio-neutral-face:before {
    content: "\eade";
}
.tio-protection:before {
    content: "\eadf";
}
.tio-sad:before {
    content: "\eae0";
}
.tio-slap:before {
    content: "\eae1";
}
.tio-slightly-smilling:before {
    content: "\eae2";
}
.tio-smile:before {
    content: "\eae3";
}
.tio-thumbs-down:before {
    content: "\eae4";
}
.tio-thumbs-up-down:before {
    content: "\eae5";
}
.tio-thumbs-up:before {
    content: "\eae6";
}
.tio-wink:before {
    content: "\eae7";
}
.tio-alt:before {
    content: "\eae8";
}
.tio-backspace-outlined:before {
    content: "\eae9";
}
.tio-backspace:before {
    content: "\eaea";
}
.tio-caps-lock:before {
    content: "\eaeb";
}
.tio-command-key:before {
    content: "\eaec";
}
.tio-enter:before {
    content: "\eaed";
}
.tio-keyboard-brightness-1:before {
    content: "\eaee";
}
.tio-keyboard-brightness-2:before {
    content: "\eaef";
}
.tio-space-bar:before {
    content: "\eaf0";
}
.tio-tab-key:before {
    content: "\eaf1";
}
.tio-rotate:before {
    content: "\eaf2";
}
.tio-beijing:before {
    content: "\eaf3";
}
.tio-bratislava:before {
    content: "\eaf4";
}
.tio-broadleaf-tree-outlined:before {
    content: "\eaf5";
}
.tio-broadleaf-tree:before {
    content: "\eaf6";
}
.tio-cafe-outlined:before {
    content: "\eaf7";
}
.tio-cafe:before {
    content: "\eaf8";
}
.tio-cairo:before {
    content: "\eaf9";
}
.tio-castle:before {
    content: "\eafa";
}
.tio-city:before {
    content: "\eafb";
}
.tio-clinic:before {
    content: "\eafc";
}
.tio-company:before {
    content: "\eafd";
}
.tio-conifer-outlined:before {
    content: "\eafe";
}
.tio-conifer:before {
    content: "\eaff";
}
.tio-direction:before {
    content: "\eb00";
}
.tio-dubai:before {
    content: "\eb01";
}
.tio-earth-east:before {
    content: "\eb02";
}
.tio-earth-west:before {
    content: "\eb03";
}
.tio-education-outlined:before {
    content: "\eb04";
}
.tio-education:before {
    content: "\eb05";
}
.tio-factory:before {
    content: "\eb06";
}
.tio-gas-station:before {
    content: "\eb07";
}
.tio-globe:before {
    content: "\eb08";
}
.tio-gym:before {
    content: "\eb09";
}
.tio-hospital:before {
    content: "\eb0a";
}
.tio-hotel:before {
    content: "\eb0b";
}
.tio-location-off:before {
    content: "\eb0c";
}
.tio-location-search:before {
    content: "\eb0d";
}
.tio-machu-picchu:before {
    content: "\eb0e";
}
.tio-map-outlined:before {
    content: "\eb0f";
}
.tio-map-zoom-out:before {
    content: "\eb10";
}
.tio-map:before {
    content: "\eb11";
}
.tio-museum:before {
    content: "\eb12";
}
.tio-my-location:before {
    content: "\eb13";
}
.tio-navigate-outlined:before {
    content: "\eb14";
}
.tio-navigate:before {
    content: "\eb15";
}
.tio-neighborhood:before {
    content: "\eb16";
}
.tio-new-york:before {
    content: "\eb17";
}
.tio-paris:before {
    content: "\eb18";
}
.tio-parking-outlined:before {
    content: "\eb19";
}
.tio-parking:before {
    content: "\eb1a";
}
.tio-pharmacy-outlined:before {
    content: "\eb1b";
}
.tio-pharmacy:before {
    content: "\eb1c";
}
.tio-pin-outlined:before {
    content: "\eb1d";
}
.tio-pin:before {
    content: "\eb1e";
}
.tio-poi-add-outlined:before {
    content: "\eb1f";
}
.tio-poi-add:before {
    content: "\eb20";
}
.tio-poi-delete-outlined:before {
    content: "\eb21";
}
.tio-poi-delete:before {
    content: "\eb22";
}
.tio-poi-outlined:before {
    content: "\eb23";
}
.tio-poi-remove-outlined:before {
    content: "\eb24";
}
.tio-poi-remove:before {
    content: "\eb25";
}
.tio-poi-user:before {
    content: "\eb26";
}
.tio-poi:before {
    content: "\eb27";
}
.tio-pyramid:before {
    content: "\eb28";
}
.tio-restaurant:before {
    content: "\eb29";
}
.tio-rome:before {
    content: "\eb2a";
}
.tio-route-dashed:before {
    content: "\eb2b";
}
.tio-route:before {
    content: "\eb2c";
}
.tio-san-francisco:before {
    content: "\eb2d";
}
.tio-shop-outlined:before {
    content: "\eb2e";
}
.tio-shop:before {
    content: "\eb2f";
}
.tio-shopping-icon:before {
    content: "\eb30";
}
.tio-shopping:before {
    content: "\eb31";
}
.tio-singapore:before {
    content: "\eb32";
}
.tio-spa-outlined:before {
    content: "\eb33";
}
.tio-spa:before {
    content: "\eb34";
}
.tio-sydney:before {
    content: "\eb35";
}
.tio-theatre:before {
    content: "\eb36";
}
.tio-toilets:before {
    content: "\eb37";
}
.tio-trencin:before {
    content: "\eb38";
}
.tio-zoom-in:before {
    content: "\eb39";
}
.tio-zoom-out:before {
    content: "\eb3a";
}
.tio-add-photo:before {
    content: "\eb3b";
}
.tio-adjust:before {
    content: "\eb3c";
}
.tio-align-to-bottom-outlined:before {
    content: "\eb3d";
}
.tio-align-to-bottom-vs:before {
    content: "\eb3e";
}
.tio-align-to-bottom:before {
    content: "\eb3f";
}
.tio-align-to-center-outlined:before {
    content: "\eb40";
}
.tio-align-to-center-vs:before {
    content: "\eb41";
}
.tio-align-to-center:before {
    content: "\eb42";
}
.tio-align-to-left-outlined:before {
    content: "\eb43";
}
.tio-align-to-left-vs:before {
    content: "\eb44";
}
.tio-align-to-left:before {
    content: "\eb45";
}
.tio-align-to-middle-outlined:before {
    content: "\eb46";
}
.tio-align-to-middle-vs:before {
    content: "\eb47";
}
.tio-align-to-middle:before {
    content: "\eb48";
}
.tio-align-to-right-outlined:before {
    content: "\eb49";
}
.tio-align-to-right-vs:before {
    content: "\eb4a";
}
.tio-align-to-right:before {
    content: "\eb4b";
}
.tio-align-to-top-outlined:before {
    content: "\eb4c";
}
.tio-align-to-top-vs:before {
    content: "\eb4d";
}
.tio-align-to-top:before {
    content: "\eb4e";
}
.tio-artboard-outlined:before {
    content: "\eb4f";
}
.tio-artboard:before {
    content: "\eb50";
}
.tio-black-white:before {
    content: "\eb51";
}
.tio-blend-tool:before {
    content: "\eb52";
}
.tio-bold:before {
    content: "\eb53";
}
.tio-brightness-1-outlined:before {
    content: "\eb54";
}
.tio-brightness-1:before {
    content: "\eb55";
}
.tio-brightness-2-outlined:before {
    content: "\eb56";
}
.tio-brightness-2:before {
    content: "\eb57";
}
.tio-brightness-3:before {
    content: "\eb58";
}
.tio-bring-forward:before {
    content: "\eb59";
}
.tio-bring-to-front:before {
    content: "\eb5a";
}
.tio-brush:before {
    content: "\eb5b";
}
.tio-camera-enhance:before {
    content: "\eb5c";
}
.tio-camera-roll:before {
    content: "\eb5d";
}
.tio-canvas-graphics:before {
    content: "\eb5e";
}
.tio-canvas-text:before {
    content: "\eb5f";
}
.tio-canvas:before {
    content: "\eb60";
}
.tio-color-off-outlined:before {
    content: "\eb61";
}
.tio-color-off:before {
    content: "\eb62";
}
.tio-color-outlined:before {
    content: "\eb63";
}
.tio-color-picker-empty:before {
    content: "\eb64";
}
.tio-color-picker-point:before {
    content: "\eb65";
}
.tio-color-picker:before {
    content: "\eb66";
}
.tio-color:before {
    content: "\eb67";
}
.tio-copy:before {
    content: "\eb68";
}
.tio-crop:before {
    content: "\eb69";
}
.tio-cut-in-half:before {
    content: "\eb6a";
}
.tio-cut:before {
    content: "\eb6b";
}
.tio-difference:before {
    content: "\eb6c";
}
.tio-eraser:before {
    content: "\eb6d";
}
.tio-exposure:before {
    content: "\eb6e";
}
.tio-flare:before {
    content: "\eb6f";
}
.tio-flash-off:before {
    content: "\eb70";
}
.tio-flash:before {
    content: "\eb71";
}
.tio-focus:before {
    content: "\eb72";
}
.tio-format-1-1:before {
    content: "\eb73";
}
.tio-format-4-3:before {
    content: "\eb74";
}
.tio-format-16-9:before {
    content: "\eb75";
}
.tio-formats:before {
    content: "\eb76";
}
.tio-free-transform:before {
    content: "\eb77";
}
.tio-fullscreen-1-1:before {
    content: "\eb78";
}
.tio-fullscreen-4-3:before {
    content: "\eb79";
}
.tio-fullscreen-16-9:before {
    content: "\eb7a";
}
.tio-gesture:before {
    content: "\eb7b";
}
.tio-gradient:before {
    content: "\eb7c";
}
.tio-grid:before {
    content: "\eb7d";
}
.tio-image-rotate-left:before {
    content: "\eb7e";
}
.tio-image-rotate-right:before {
    content: "\eb7f";
}
.tio-image:before {
    content: "\eb80";
}
.tio-intersect:before {
    content: "\eb81";
}
.tio-invert-colors-off:before {
    content: "\eb82";
}
.tio-invert-colors:before {
    content: "\eb83";
}
.tio-invert:before {
    content: "\eb84";
}
.tio-italic:before {
    content: "\eb85";
}
.tio-keyframe-bezier-in:before {
    content: "\eb86";
}
.tio-keyframe-bezier-out:before {
    content: "\eb87";
}
.tio-keyframe-cont-bezier:before {
    content: "\eb88";
}
.tio-keyframe-linear-in:before {
    content: "\eb89";
}
.tio-keyframe-linear-out:before {
    content: "\eb8a";
}
.tio-keyframe-linear:before {
    content: "\eb8b";
}
.tio-knife:before {
    content: "\eb8c";
}
.tio-lasso:before {
    content: "\eb8d";
}
.tio-layers-off:before {
    content: "\eb8e";
}
.tio-layers-outlined:before {
    content: "\eb8f";
}
.tio-layers:before {
    content: "\eb90";
}
.tio-ligature:before {
    content: "\eb91";
}
.tio-macro-outlined:before {
    content: "\eb92";
}
.tio-macro:before {
    content: "\eb93";
}
.tio-magic-wand:before {
    content: "\eb94";
}
.tio-marker-outlined:before {
    content: "\eb95";
}
.tio-marker:before {
    content: "\eb96";
}
.tio-mask-vs:before {
    content: "\eb97";
}
.tio-mask:before {
    content: "\eb98";
}
.tio-material:before {
    content: "\eb99";
}
.tio-media-photo:before {
    content: "\eb9a";
}
.tio-mesh-grid:before {
    content: "\eb9b";
}
.tio-mouse-cursor-outlined:before {
    content: "\eb9c";
}
.tio-mouse-cursor:before {
    content: "\eb9d";
}
.tio-opacity:before {
    content: "\eb9e";
}
.tio-paint-bucket:before {
    content: "\eb9f";
}
.tio-paint-outlined:before {
    content: "\eba0";
}
.tio-paint:before {
    content: "\eba1";
}
.tio-palette-outlined:before {
    content: "\eba2";
}
.tio-palette:before {
    content: "\eba3";
}
.tio-pan-tool:before {
    content: "\eba4";
}
.tio-panorama-image:before {
    content: "\eba5";
}
.tio-panorama-outlined:before {
    content: "\eba6";
}
.tio-panorama-vs-outlined:before {
    content: "\eba7";
}
.tio-panorama-vs:before {
    content: "\eba8";
}
.tio-panorama:before {
    content: "\eba9";
}
.tio-paste:before {
    content: "\ebaa";
}
.tio-pattern:before {
    content: "\ebab";
}
.tio-pen:before {
    content: "\ebac";
}
.tio-photo-album:before {
    content: "\ebad";
}
.tio-photo-filter:before {
    content: "\ebae";
}
.tio-photo-gallery-outlined:before {
    content: "\ebaf";
}
.tio-photo-gallery:before {
    content: "\ebb0";
}
.tio-photo-landscape-outlined:before {
    content: "\ebb1";
}
.tio-photo-landscape:before {
    content: "\ebb2";
}
.tio-photo-square-outlined:before {
    content: "\ebb3";
}
.tio-photo-square:before {
    content: "\ebb4";
}
.tio-podcast:before {
    content: "\ebb5";
}
.tio-print-disabled:before {
    content: "\ebb6";
}
.tio-print:before {
    content: "\ebb7";
}
.tio-redo:before {
    content: "\ebb8";
}
.tio-reflect-horizontal:before {
    content: "\ebb9";
}
.tio-reflect-vertical:before {
    content: "\ebba";
}
.tio-rounded-corner:before {
    content: "\ebbb";
}
.tio-ruler:before {
    content: "\ebbc";
}
.tio-save-outlined:before {
    content: "\ebbd";
}
.tio-save:before {
    content: "\ebbe";
}
.tio-scan-document-icon:before {
    content: "\ebbf";
}
.tio-scan-document:before {
    content: "\ebc0";
}
.tio-selection:before {
    content: "\ebc1";
}
.tio-send-backward:before {
    content: "\ebc2";
}
.tio-send-to-back:before {
    content: "\ebc3";
}
.tio-sharpness:before {
    content: "\ebc4";
}
.tio-shear:before {
    content: "\ebc5";
}
.tio-shutter-outlined:before {
    content: "\ebc6";
}
.tio-shutter:before {
    content: "\ebc7";
}
.tio-slideshow-outlined:before {
    content: "\ebc8";
}
.tio-slideshow:before {
    content: "\ebc9";
}
.tio-small-caps:before {
    content: "\ebca";
}
.tio-snap-to:before {
    content: "\ebcb";
}
.tio-spiral:before {
    content: "\ebcc";
}
.tio-stamp:before {
    content: "\ebcd";
}
.tio-strikethrough:before {
    content: "\ebce";
}
.tio-stroke-weight:before {
    content: "\ebcf";
}
.tio-substract:before {
    content: "\ebd0";
}
.tio-switch-camera:before {
    content: "\ebd1";
}
.tio-switch-video:before {
    content: "\ebd2";
}
.tio-text-center:before {
    content: "\ebd3";
}
.tio-text-left:before {
    content: "\ebd4";
}
.tio-text-right:before {
    content: "\ebd5";
}
.tio-text:before {
    content: "\ebd6";
}
.tio-texture:before {
    content: "\ebd7";
}
.tio-tools:before {
    content: "\ebd8";
}
.tio-transform-artboard:before {
    content: "\ebd9";
}
.tio-transform:before {
    content: "\ebda";
}
.tio-underline:before {
    content: "\ebdb";
}
.tio-undo:before {
    content: "\ebdc";
}
.tio-union:before {
    content: "\ebdd";
}
.tio-vector:before {
    content: "\ebde";
}
.tio-vertical-align-bottom:before {
    content: "\ebdf";
}
.tio-vertical-align-middle:before {
    content: "\ebe0";
}
.tio-vertical-align-top:before {
    content: "\ebe1";
}
.tio-video-add:before {
    content: "\ebe2";
}
.tio-video-gallery-outlined:before {
    content: "\ebe3";
}
.tio-video-gallery:before {
    content: "\ebe4";
}
.tio-video-horizontal-outlined:before {
    content: "\ebe5";
}
.tio-video-horizontal:before {
    content: "\ebe6";
}
.tio-video-vertical-outlined:before {
    content: "\ebe7";
}
.tio-video-vertical:before {
    content: "\ebe8";
}
.tio-american-football:before {
    content: "\ebe9";
}
.tio-anchor:before {
    content: "\ebea";
}
.tio-armchair:before {
    content: "\ebeb";
}
.tio-baseball:before {
    content: "\ebec";
}
.tio-basketball:before {
    content: "\ebed";
}
.tio-birthday-outlined:before {
    content: "\ebee";
}
.tio-birthday:before {
    content: "\ebef";
}
.tio-block:before {
    content: "\ebf0";
}
.tio-book-bookmarked:before {
    content: "\ebf1";
}
.tio-book-opened:before {
    content: "\ebf2";
}
.tio-book-outlined:before {
    content: "\ebf3";
}
.tio-book:before {
    content: "\ebf4";
}
.tio-brain:before {
    content: "\ebf5";
}
.tio-brick-wall:before {
    content: "\ebf6";
}
.tio-briefcase-not-accessible:before {
    content: "\ebf7";
}
.tio-briefcase-outlined:before {
    content: "\ebf8";
}
.tio-briefcase:before {
    content: "\ebf9";
}
.tio-chair:before {
    content: "\ebfa";
}
.tio-circle-outlined:before {
    content: "\ebfb";
}
.tio-circle:before {
    content: "\ebfc";
}
.tio-cricket:before {
    content: "\ebfd";
}
.tio-crossfit:before {
    content: "\ebfe";
}
.tio-cube:before {
    content: "\ebff";
}
.tio-dining-table-outlined:before {
    content: "\ec00";
}
.tio-dining-table:before {
    content: "\ec01";
}
.tio-diving:before {
    content: "\ec02";
}
.tio-door:before {
    content: "\ec03";
}
.tio-flag-cross-1:before {
    content: "\ec04";
}
.tio-flag-cross-2:before {
    content: "\ec05";
}
.tio-flag-half-1:before {
    content: "\ec06";
}
.tio-flag-half-2:before {
    content: "\ec07";
}
.tio-flag-outlined:before {
    content: "\ec08";
}
.tio-flag-quarter-1:before {
    content: "\ec09";
}
.tio-flag-quarter-2:before {
    content: "\ec0a";
}
.tio-flag:before {
    content: "\ec0b";
}
.tio-flask:before {
    content: "\ec0c";
}
.tio-flower:before {
    content: "\ec0d";
}
.tio-foot-print:before {
    content: "\ec0e";
}
.tio-gift:before {
    content: "\ec0f";
}
.tio-glasses-outlined:before {
    content: "\ec10";
}
.tio-glasses:before {
    content: "\ec11";
}
.tio-hourglass-half:before {
    content: "\ec12";
}
.tio-hourglass-outlined:before {
    content: "\ec13";
}
.tio-hourglass-quarter:before {
    content: "\ec14";
}
.tio-hourglass:before {
    content: "\ec15";
}
.tio-ice-hockey:before {
    content: "\ec16";
}
.tio-infinity:before {
    content: "\ec17";
}
.tio-justice:before {
    content: "\ec18";
}
.tio-lab:before {
    content: "\ec19";
}
.tio-leaf-diagonal:before {
    content: "\ec1a";
}
.tio-leaf:before {
    content: "\ec1b";
}
.tio-light-on:before {
    content: "\ec1c";
}
.tio-lightbulb:before {
    content: "\ec1d";
}
.tio-medicaments:before {
    content: "\ec1e";
}
.tio-mma:before {
    content: "\ec1f";
}
.tio-moon-outlined:before {
    content: "\ec20";
}
.tio-moon-vs-outlined:before {
    content: "\ec21";
}
.tio-moon-vs:before {
    content: "\ec22";
}
.tio-moon:before {
    content: "\ec23";
}
.tio-movie:before {
    content: "\ec24";
}
.tio-node-multiple-outlined:before {
    content: "\ec25";
}
.tio-node-multiple:before {
    content: "\ec26";
}
.tio-node-outlined:before {
    content: "\ec27";
}
.tio-node:before {
    content: "\ec28";
}
.tio-pet:before {
    content: "\ec29";
}
.tio-pill:before {
    content: "\ec2a";
}
.tio-puzzle:before {
    content: "\ec2b";
}
.tio-quotes:before {
    content: "\ec2c";
}
.tio-recycling:before {
    content: "\ec2d";
}
.tio-rugby:before {
    content: "\ec2e";
}
.tio-seat-outlined:before {
    content: "\ec2f";
}
.tio-seat:before {
    content: "\ec30";
}
.tio-shoe-print-outlined:before {
    content: "\ec31";
}
.tio-shoe-print:before {
    content: "\ec32";
}
.tio-shower:before {
    content: "\ec33";
}
.tio-sign-man:before {
    content: "\ec34";
}
.tio-sign-woman:before {
    content: "\ec35";
}
.tio-skull:before {
    content: "\ec36";
}
.tio-smoke-free:before {
    content: "\ec37";
}
.tio-smoking:before {
    content: "\ec38";
}
.tio-snowflake:before {
    content: "\ec39";
}
.tio-sport:before {
    content: "\ec3a";
}
.tio-square-outlined:before {
    content: "\ec3b";
}
.tio-square:before {
    content: "\ec3c";
}
.tio-stairs-down:before {
    content: "\ec3d";
}
.tio-stairs-up:before {
    content: "\ec3e";
}
.tio-survey:before {
    content: "\ec3f";
}
.tio-t-shirt:before {
    content: "\ec40";
}
.tio-table-tennis:before {
    content: "\ec41";
}
.tio-temperature:before {
    content: "\ec42";
}
.tio-tennis:before {
    content: "\ec43";
}
.tio-test-tube:before {
    content: "\ec44";
}
.tio-ticket:before {
    content: "\ec45";
}
.tio-triangle-outlined:before {
    content: "\ec46";
}
.tio-triangle:before {
    content: "\ec47";
}
.tio-umbrella-outlined:before {
    content: "\ec48";
}
.tio-umbrella:before {
    content: "\ec49";
}
.tio-volleyball:before {
    content: "\ec4a";
}
.tio-weight-outlined:before {
    content: "\ec4b";
}
.tio-weight:before {
    content: "\ec4c";
}
.tio-wishlist-outlined:before {
    content: "\ec4d";
}
.tio-wishlist:before {
    content: "\ec4e";
}
.tio-wrench:before {
    content: "\ec4f";
}
.tio-american-express:before {
    content: "\ec50";
}
.tio-atm-outlined:before {
    content: "\ec51";
}
.tio-atm:before {
    content: "\ec52";
}
.tio-bitcoin-outlined:before {
    content: "\ec53";
}
.tio-bitcoin:before {
    content: "\ec54";
}
.tio-coin:before {
    content: "\ec55";
}
.tio-credit-card-add:before {
    content: "\ec56";
}
.tio-credit-card-outlined:before {
    content: "\ec57";
}
.tio-credit-card-remove:before {
    content: "\ec58";
}
.tio-credit-card:before {
    content: "\ec59";
}
.tio-credit-cards:before {
    content: "\ec5a";
}
.tio-discover:before {
    content: "\ec5b";
}
.tio-dollar-outlined:before {
    content: "\ec5c";
}
.tio-dollar:before {
    content: "\ec5d";
}
.tio-ethereum-outlined:before {
    content: "\ec5e";
}
.tio-ethereum:before {
    content: "\ec5f";
}
.tio-euro-outlined:before {
    content: "\ec60";
}
.tio-euro:before {
    content: "\ec61";
}
.tio-insurance:before {
    content: "\ec62";
}
.tio-litecoin-outlined:before {
    content: "\ec63";
}
.tio-litecoin:before {
    content: "\ec64";
}
.tio-mastercard:before {
    content: "\ec65";
}
.tio-money-vs:before {
    content: "\ec66";
}
.tio-money:before {
    content: "\ec67";
}
.tio-paypal:before {
    content: "\ec68";
}
.tio-pound-outlined:before {
    content: "\ec69";
}
.tio-pound:before {
    content: "\ec6a";
}
.tio-receipt-outlined:before {
    content: "\ec6b";
}
.tio-receipt:before {
    content: "\ec6c";
}
.tio-saving-outlined:before {
    content: "\ec6d";
}
.tio-saving:before {
    content: "\ec6e";
}
.tio-savings:before {
    content: "\ec6f";
}
.tio-visa:before {
    content: "\ec70";
}
.tio-wallet-outlined:before {
    content: "\ec71";
}
.tio-wallet:before {
    content: "\ec72";
}
.tio-accessibility-vs:before {
    content: "\ec73";
}
.tio-accessibility:before {
    content: "\ec74";
}
.tio-accessible-forward:before {
    content: "\ec75";
}
.tio-accessible:before {
    content: "\ec76";
}
.tio-hiking:before {
    content: "\ec77";
}
.tio-man:before {
    content: "\ec78";
}
.tio-pregnancy:before {
    content: "\ec79";
}
.tio-running:before {
    content: "\ec7a";
}
.tio-standing:before {
    content: "\ec7b";
}
.tio-swimming:before {
    content: "\ec7c";
}
.tio-walking:before {
    content: "\ec7d";
}
.tio-woman:before {
    content: "\ec7e";
}
.tio-award-outlined:before {
    content: "\ec7f";
}
.tio-award:before {
    content: "\ec80";
}
.tio-boost:before {
    content: "\ec81";
}
.tio-crown-outlined:before {
    content: "\ec82";
}
.tio-crown:before {
    content: "\ec83";
}
.tio-diamond-outlined:before {
    content: "\ec84";
}
.tio-diamond:before {
    content: "\ec85";
}
.tio-dna-spiral:before {
    content: "\ec86";
}
.tio-luck:before {
    content: "\ec87";
}
.tio-medal:before {
    content: "\ec88";
}
.tio-planet:before {
    content: "\ec89";
}
.tio-premium-outlined:before {
    content: "\ec8a";
}
.tio-premium:before {
    content: "\ec8b";
}
.tio-robot:before {
    content: "\ec8c";
}
.tio-sticker-outlined:before {
    content: "\ec8d";
}
.tio-sticker:before {
    content: "\ec8e";
}
.tio-top-security-outlined:before {
    content: "\ec8f";
}
.tio-top-security:before {
    content: "\ec90";
}
.tio-verified-outlined:before {
    content: "\ec91";
}
.tio-verified:before {
    content: "\ec92";
}
.tio-appstore:before {
    content: "\ec93";
}
.tio-behance:before {
    content: "\ec94";
}
.tio-dribbble:before {
    content: "\ec95";
}
.tio-dropbox:before {
    content: "\ec96";
}
.tio-facebook-square:before {
    content: "\ec97";
}
.tio-facebook:before {
    content: "\ec98";
}
.tio-github:before {
    content: "\ec99";
}
.tio-gmail-outlined:before {
    content: "\ec9a";
}
.tio-gmail:before {
    content: "\ec9b";
}
.tio-google-drive:before {
    content: "\ec9c";
}
.tio-google-play:before {
    content: "\ec9d";
}
.tio-google:before {
    content: "\ec9e";
}
.tio-hangouts-outlined:before {
    content: "\ec9f";
}
.tio-hangouts:before {
    content: "\eca0";
}
.tio-instagram:before {
    content: "\eca1";
}
.tio-linkedin-square:before {
    content: "\eca2";
}
.tio-linkedin:before {
    content: "\eca3";
}
.tio-medium-square:before {
    content: "\eca4";
}
.tio-medium:before {
    content: "\eca5";
}
.tio-messenger-outlined:before {
    content: "\eca6";
}
.tio-messenger:before {
    content: "\eca7";
}
.tio-pinterest-circle:before {
    content: "\eca8";
}
.tio-pinterest:before {
    content: "\eca9";
}
.tio-reddit:before {
    content: "\ecaa";
}
.tio-rss:before {
    content: "\ecab";
}
.tio-skype:before {
    content: "\ecac";
}
.tio-slack:before {
    content: "\ecad";
}
.tio-snapchat-outlined:before {
    content: "\ecae";
}
.tio-snapchat:before {
    content: "\ecaf";
}
.tio-steam:before {
    content: "\ecb0";
}
.tio-telegram:before {
    content: "\ecb1";
}
.tio-tik-tok:before {
    content: "\ecb2";
}
.tio-twitch:before {
    content: "\ecb3";
}
.tio-twitter:before {
    content: "\ecb4";
}
.tio-viber-outlined:before {
    content: "\ecb5";
}
.tio-viber:before {
    content: "\ecb6";
}
.tio-vimeo-square:before {
    content: "\ecb7";
}
.tio-vimeo:before {
    content: "\ecb8";
}
.tio-vkontakte:before {
    content: "\ecb9";
}
.tio-whatsapp-outlined:before {
    content: "\ecba";
}
.tio-whatsapp:before {
    content: "\ecbb";
}
.tio-youtube-outlined:before {
    content: "\ecbc";
}
.tio-youtube:before {
    content: "\ecbd";
}
.tio-airdrop:before {
    content: "\ecbe";
}
.tio-airplay:before {
    content: "\ecbf";
}
.tio-android:before {
    content: "\ecc0";
}
.tio-apple-outlined:before {
    content: "\ecc1";
}
.tio-apple:before {
    content: "\ecc2";
}
.tio-augmented-reality:before {
    content: "\ecc3";
}
.tio-barcode:before {
    content: "\ecc4";
}
.tio-bluetooth-connect:before {
    content: "\ecc5";
}
.tio-bluetooth-off:before {
    content: "\ecc6";
}
.tio-bluetooth-search:before {
    content: "\ecc7";
}
.tio-bluetooth:before {
    content: "\ecc8";
}
.tio-chart-bar-1:before {
    content: "\ecc9";
}
.tio-chart-bar-2:before {
    content: "\ecca";
}
.tio-chart-bar-3:before {
    content: "\eccb";
}
.tio-chart-bar-4:before {
    content: "\eccc";
}
.tio-chart-bubble:before {
    content: "\eccd";
}
.tio-chart-donut-1:before {
    content: "\ecce";
}
.tio-chart-donut-2:before {
    content: "\eccf";
}
.tio-chart-line-down:before {
    content: "\ecd0";
}
.tio-chart-line-up:before {
    content: "\ecd1";
}
.tio-chart-pie-1:before {
    content: "\ecd2";
}
.tio-chart-pie-2:before {
    content: "\ecd3";
}
.tio-cloud-disabled:before {
    content: "\ecd4";
}
.tio-cloud-off-outlined:before {
    content: "\ecd5";
}
.tio-cloud-off:before {
    content: "\ecd6";
}
.tio-cloud-on:before {
    content: "\ecd7";
}
.tio-cloud-outlined:before {
    content: "\ecd8";
}
.tio-cloud:before {
    content: "\ecd9";
}
.tio-code:before {
    content: "\ecda";
}
.tio-data-sharing:before {
    content: "\ecdb";
}
.tio-download-from-cloud:before {
    content: "\ecdc";
}
.tio-face-id:before {
    content: "\ecdd";
}
.tio-fingerprint:before {
    content: "\ecde";
}
.tio-link-off:before {
    content: "\ecdf";
}
.tio-link:before {
    content: "\ece0";
}
.tio-memory-chip:before {
    content: "\ece1";
}
.tio-mobile-data-low:before {
    content: "\ece2";
}
.tio-mobile-data:before {
    content: "\ece3";
}
.tio-online:before {
    content: "\ece4";
}
.tio-plugin-outlined:before {
    content: "\ece5";
}
.tio-plugin:before {
    content: "\ece6";
}
.tio-poll:before {
    content: "\ece7";
}
.tio-qr-code:before {
    content: "\ece8";
}
.tio-scan:before {
    content: "\ece9";
}
.tio-security-off-outlined:before {
    content: "\ecea";
}
.tio-security-off:before {
    content: "\eceb";
}
.tio-security-on-outlined:before {
    content: "\ecec";
}
.tio-security-on:before {
    content: "\eced";
}
.tio-security:before {
    content: "\ecee";
}
.tio-touch-id:before {
    content: "\ecef";
}
.tio-trending-down:before {
    content: "\ecf0";
}
.tio-trending-up:before {
    content: "\ecf1";
}
.tio-upload-on-cloud:before {
    content: "\ecf2";
}
.tio-usb:before {
    content: "\ecf3";
}
.tio-virtual-reality-outlined:before {
    content: "\ecf4";
}
.tio-virtual-reality:before {
    content: "\ecf5";
}
.tio-website:before {
    content: "\ecf6";
}
.tio-wifi-off:before {
    content: "\ecf7";
}
.tio-wifi:before {
    content: "\ecf8";
}
.tio-windows:before {
    content: "\ecf9";
}
.tio-arrival:before {
    content: "\ecfa";
}
.tio-bike:before {
    content: "\ecfb";
}
.tio-boat:before {
    content: "\ecfc";
}
.tio-bus:before {
    content: "\ecfd";
}
.tio-car-police:before {
    content: "\ecfe";
}
.tio-car:before {
    content: "\ecff";
}
.tio-departure:before {
    content: "\ed00";
}
.tio-flight-cancelled:before {
    content: "\ed01";
}
.tio-flight:before {
    content: "\ed02";
}
.tio-metro:before {
    content: "\ed03";
}
.tio-motocycle:before {
    content: "\ed04";
}
.tio-taxi:before {
    content: "\ed05";
}
.tio-traffic-light:before {
    content: "\ed06";
}
.tio-train:before {
    content: "\ed07";
}
.tio-tram:before {
    content: "\ed08";
}
.tio-truck:before {
    content: "\ed09";
}
.tio-add-event:before {
    content: "\ed0a";
}
.tio-add-to-trash:before {
    content: "\ed0b";
}
.tio-agenda-view-outlined:before {
    content: "\ed0c";
}
.tio-agenda-view:before {
    content: "\ed0d";
}
.tio-alarm-add:before {
    content: "\ed0e";
}
.tio-alarm-alert:before {
    content: "\ed0f";
}
.tio-alarm-off:before {
    content: "\ed10";
}
.tio-alarm-on:before {
    content: "\ed11";
}
.tio-alarm:before {
    content: "\ed12";
}
.tio-album:before {
    content: "\ed13";
}
.tio-albums:before {
    content: "\ed14";
}
.tio-all-done:before {
    content: "\ed15";
}
.tio-apps:before {
    content: "\ed16";
}
.tio-attachment-diagonal:before {
    content: "\ed17";
}
.tio-attachment:before {
    content: "\ed18";
}
.tio-blocked:before {
    content: "\ed19";
}
.tio-category-outlined:before {
    content: "\ed1a";
}
.tio-category:before {
    content: "\ed1b";
}
.tio-clock:before {
    content: "\ed1c";
}
.tio-dashboard-outlined:before {
    content: "\ed1d";
}
.tio-dashboard-vs-outlined:before {
    content: "\ed1e";
}
.tio-dashboard-vs:before {
    content: "\ed1f";
}
.tio-dashboard:before {
    content: "\ed20";
}
.tio-delete-outlined:before {
    content: "\ed21";
}
.tio-delete:before {
    content: "\ed22";
}
.tio-download-outlined:before {
    content: "\ed23";
}
.tio-download-to:before {
    content: "\ed24";
}
.tio-download:before {
    content: "\ed25";
}
.tio-edit:before {
    content: "\ed26";
}
.tio-explore-off-outlined:before {
    content: "\ed27";
}
.tio-explore-off:before {
    content: "\ed28";
}
.tio-explore-outlined:before {
    content: "\ed29";
}
.tio-explore:before {
    content: "\ed2a";
}
.tio-feed-outlined:before {
    content: "\ed2b";
}
.tio-feed:before {
    content: "\ed2c";
}
.tio-filter-list:before {
    content: "\ed2d";
}
.tio-filter-outlined:before {
    content: "\ed2e";
}
.tio-filter:before {
    content: "\ed2f";
}
.tio-format-bullets:before {
    content: "\ed30";
}
.tio-format-points:before {
    content: "\ed31";
}
.tio-hidden-outlined:before {
    content: "\ed32";
}
.tio-hidden:before {
    content: "\ed33";
}
.tio-home-outlined:before {
    content: "\ed34";
}
.tio-home-vs-1-outlined:before {
    content: "\ed35";
}
.tio-home-vs-2-outlined:before {
    content: "\ed36";
}
.tio-home-vs:before {
    content: "\ed37";
}
.tio-home:before {
    content: "\ed38";
}
.tio-hot:before {
    content: "\ed39";
}
.tio-import:before {
    content: "\ed3a";
}
.tio-invisible:before {
    content: "\ed3b";
}
.tio-launch-outlined:before {
    content: "\ed3c";
}
.tio-launch-vs-outlined:before {
    content: "\ed3d";
}
.tio-launch-vs:before {
    content: "\ed3e";
}
.tio-launch:before {
    content: "\ed3f";
}
.tio-lock-open-outlined:before {
    content: "\ed40";
}
.tio-lock-opened:before {
    content: "\ed41";
}
.tio-lock-outlined:before {
    content: "\ed42";
}
.tio-lock:before {
    content: "\ed43";
}
.tio-menu-hamburger:before {
    content: "\ed44";
}
.tio-menu-left-right:before {
    content: "\ed45";
}
.tio-menu-left:before {
    content: "\ed46";
}
.tio-menu-vs-outlined:before {
    content: "\ed47";
}
.tio-menu-vs:before {
    content: "\ed48";
}
.tio-more-horizontal:before {
    content: "\ed49";
}
.tio-more-vertical:before {
    content: "\ed4a";
}
.tio-new-message:before {
    content: "\ed4b";
}
.tio-password-open:before {
    content: "\ed4c";
}
.tio-password:before {
    content: "\ed4d";
}
.tio-remove-from-trash:before {
    content: "\ed4e";
}
.tio-settings-outlined:before {
    content: "\ed4f";
}
.tio-settings-vs-outlined:before {
    content: "\ed50";
}
.tio-settings-vs:before {
    content: "\ed51";
}
.tio-settings:before {
    content: "\ed52";
}
.tio-share-vs:before {
    content: "\ed53";
}
.tio-share:before {
    content: "\ed54";
}
.tio-sign-in:before {
    content: "\ed55";
}
.tio-sign-out:before {
    content: "\ed56";
}
.tio-support-vs:before {
    content: "\ed57";
}
.tio-table:before {
    content: "\ed58";
}
.tio-toggle-off:before {
    content: "\ed59";
}
.tio-toggle-on:before {
    content: "\ed5a";
}
.tio-upload-outlined:before {
    content: "\ed5b";
}
.tio-upload:before {
    content: "\ed5c";
}
.tio-visible-outlined:before {
    content: "\ed5d";
}
.tio-visible:before {
    content: "\ed5e";
}
.tio-account-circle:before {
    content: "\ed5f";
}
.tio-account-square-outlined:before {
    content: "\ed60";
}
.tio-account-square:before {
    content: "\ed61";
}
.tio-group-add:before {
    content: "\ed62";
}
.tio-group-equal:before {
    content: "\ed63";
}
.tio-group-junior:before {
    content: "\ed64";
}
.tio-group-senior:before {
    content: "\ed65";
}
.tio-user-add:before {
    content: "\ed66";
}
.tio-user-big-outlined:before {
    content: "\ed67";
}
.tio-user-big:before {
    content: "\ed68";
}
.tio-user-outlined:before {
    content: "\ed69";
}
.tio-user-switch:before {
    content: "\ed6a";
}
.tio-user:before {
    content: "\ed6b";
}
.tio-users-switch:before {
    content: "\ed6c";
}
