table.dataTable.dtr-inline.collapsed > tbody > tr > td.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
    cursor: default !important;
}

table.dataTable.dtr-inline:not(.collapsed) > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline:not(.collapsed) > tbody > tr > th.dtr-control:before,
table.dataTable.dtr-inline:not(.collapsed) > tbody > tr > td.dataTables_empty:before {
    display: none !important;
}

table.dataTable.dtr-inline.collapsed .dtr-control {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}

table.dataTable.dtr-inline .dtr-control:before {
    top: 50%;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    display: block;
    position: absolute;
    color: white;
    border: 1px solid #00c9a7;
    border-radius: 1.25rem;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    line-height: 14px;
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z'/%3E%3C/svg%3E")
        no-repeat center center/1rem 1rem;
    content: "";
    cursor: pointer;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #00c9a7;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z'/%3E%3C/svg%3E")
        no-repeat center center/1rem 1rem;
    border: 1px solid #ed4c78;
    background-color: #ed4c78;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child {
    padding-left: 27px;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child:before {
    top: 5px;
    left: 4px;
    height: 14px;
    width: 14px;
    border-radius: 14px;
    line-height: 14px;
    text-indent: 3px;
}

table.dataTable.dtr-column > tbody > tr > td.control,
table.dataTable.dtr-column > tbody > tr > th.control {
    position: relative;
    cursor: pointer;
}

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
    top: 50%;
    left: 50%;
    height: 20px;
    width: 20px;
    margin-top: -10px;
    margin-left: -10px;
    position: absolute;
    color: white;
    border-radius: 14px;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    font-family: "feather" !important;
    content: "\e9af";
    background-color: #31b131;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.85rem;
}

table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
    content: "\e994";
    background-color: #d33333;
}

table.dataTable > tbody > tr.child {
    /* padding: 0.5em 1em; */
    padding: 0;
}

table.dataTable > tbody > tr.child td.child {
    /* padding: 0.5em 1em; */
    padding: 0 !important;
}

table.dataTable > tbody > tr.child:hover {
    background: transparent !important;
}

table.dataTable > tbody > tr.child ul.dtr-details {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li {
    border-bottom: 1px solid #efefef;
    padding: 0.5em 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:first-child {
    padding-top: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:last-child {
    border-bottom: none;
}

table.dataTable > tbody > tr.child span.dtr-title {
    display: inline-block;
    min-width: 75px;
    font-weight: bold;
}

div.dtr-modal {
    position: fixed;
    box-sizing: border-box;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    padding: 10em 1em;
}

div.dtr-modal div.dtr-modal-display {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    overflow: auto;
    margin: auto;
    z-index: 102;
    overflow: auto;
    background-color: #f5f5f7;
    border: 1px solid black;
    border-radius: 0.5em;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
}

div.dtr-modal div.dtr-modal-content {
    position: relative;
    padding: 1em;
}

div.dtr-modal div.dtr-modal-close {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 22px;
    height: 22px;
    border: 1px solid #eaeaea;
    background-color: #f9f9f9;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    z-index: 12;
}

div.dtr-modal div.dtr-modal-close:hover {
    background-color: #eaeaea;
}

div.dtr-modal div.dtr-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
    background: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 767px) {
    div.dtr-modal div.dtr-modal-display {
        width: 95%;
    }
}

table.dataTable tr.dtrg-group td {
    background-color: #e0e0e0;
}

table.dataTable tr.dtrg-group.dtrg-level-0 td {
    font-weight: bold;
}

table.dataTable tr.dtrg-group.dtrg-level-1 td,
table.dataTable tr.dtrg-group.dtrg-level-2 td {
    background-color: #f0f0f0;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    padding-left: 2em;
    font-size: 0.9em;
}

table.dataTable tr.dtrg-group.dtrg-level-2 td {
    background-color: #f3f3f3;
}
